<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}" class="whitespace-no-wrap" v-if="this.params.data.national_tax_percent != undefined || this.params.data.hasOwnProperty('recharges_total') || this.params.data.hasOwnProperty('total_pay')" v-show="this.params.data.id != 'Total'">
    <vx-tooltip class="float-left" text="Ver Detalle" v-show="this.params.data.base_amount || this.params.data.hasOwnProperty('amount_with_withholdings')">
      <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer stroke-current" class="ml-2" @click="showDetails()" />
    </vx-tooltip>
    <vx-tooltip class="float-left" text="Retenciones" v-show="this.params.data.withholdings">
      <feather-icon icon="RotateCcwIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer stroke-current" class="ml-2" @click="handleRetention()" />
    </vx-tooltip>
    <vx-tooltip class="float-left" text="Cargar Pago" v-show="this.params.data.total_pay > 0 || this.params.data.total_amount > 0 || this.params.data.final_amount_collector > 0 || this.params.data.total_after_tax > 0">
      <feather-icon icon="DollarSignIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer stroke-current" @click="submitPayment()" />
    </vx-tooltip>
  </div>
</template>

<script>
    export default {
        name: 'CellRendererActionsPendingPayments',
        methods: {
          submitPayment() {
            this.params.context.componentParent.sidebarData = this.params.data;
            this.params.context.componentParent.paymentsSidebarActive = true;
          },
          showDetails() {
            this.params.showPopup(this.params.data)
          },
          handleRetention() {
            this.params.context.componentParent.retentionSidebarData = this.params.data;
            this.params.handleRetention()
          }
        },
    }
</script>
